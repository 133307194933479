<template>
	<AuthWrapper>
		<div class="auth-contents">
			<a-form name="resetpasswordwithtoken" layout="vertical">
				<sdHeading as="h3">Reset Password</sdHeading>
				<p class="forgot-text">
					Enter the token sent by email to be able to reset the password.
				</p>
				<a-form-item name="email" label="Email Address *">
					<a-input
						type="text"
						name="email"
						v-model:value="formData.email"
						placeholder="name@example.com"
					/>
					<span class="error-messages" v-if="v$.formData.email.$error">
						{{ v$.formData.email.$errors[0].$message }}
					</span>
				</a-form-item>
				<a-form-item name="password" label="Password *">
					<a-input
						type="password"
						name="password"
						v-model:value="formData.password"
						placeholder="Password"
					/>
					<span class="error-messages" v-if="v$.formData.password.$error">
						{{ v$.formData.password.$errors[0].$message }}
					</span>
				</a-form-item>
				<a-form-item label="Confirm password" name="password_confirmation">
					<a-input
						type="password"
						name="password_confirmation"
						v-model:value="formData.password_confirmation"
						placeholder="Confirma Password"
					/>
					<span
						class="error-messages"
						v-if="v$.formData.password_confirmation.$error"
					>
						{{ v$.formData.password_confirmation.$errors[0].$message }}
					</span>
				</a-form-item>
				<a-form-item>
					<sdButton
						class="btn-reset"
						htmlType="submit"
						type="primary"
						size="large"
						@click="resetPassword"
					>
						{{ store.state.actions.isLoading ? "Loading..." : "Reset Password" }}
					</sdButton>
				</a-form-item>
				<p class="return-text">
					Return to <router-link to="/login">Sign In</router-link>
				</p>
			</a-form>
		</div>
	</AuthWrapper>
</template>
<script>
import { AuthWrapper } from "./style";

import useVuelidate from "@vuelidate/core";
import {
	required,
	minLength,
	email,
	helpers,
	sameAs
} from "@vuelidate/validators";

import UserService from "@/services/UserService";

import store from "@/vuex/store";

export default {
	name: "ForgotPassword",

	components: { AuthWrapper },

	data() {
		return {
			v$: useVuelidate(),
			isLoading: false,
			formData: {
				email: "",
				password: "",
				password_confirmation: "",
				token: window.location.href.split("?token=").pop()
			},
			store: store
		};
	},

	created() {
		this.checkIfResetPasswordTokenExist();
	},

	methods: {
		async resetPassword() {
			this.v$.formData.$touch();
			this.$store.dispatch("actions/setLoading", true);
			if (this.v$.formData.$pending || this.v$.formData.$error) {
				this.$store.dispatch("actions/setLoading", false);
				return;
			}

			await UserService.resetPasswordWithToken(this.formData);
			this.$store.dispatch("actions/setLoading", false);

			window.showAlert(
				"success",
				"Success!",
				"The password has been successfully reset, you can log in to the account again."
			);

			this.$router.push("/login");
		},

		async checkIfResetPasswordTokenExist() {
			try {
				const token = window.location.href.split("?token=").pop();
				await UserService.checkIfResetPasswordTokenExist(token);
			} catch (e) {
				window.showAlert("warning", "Warning", "Token not found or expired");
				this.$router.push("/login");
			}
		}
	},

	validations() {
		return {
			formData: {
				email: {
					required: helpers.withMessage("The field email is required", required),

					email: helpers.withMessage("The field email is not valid", email)
				},
				password: {
					required: helpers.withMessage("The field password is required", required),
					minLength: helpers.withMessage(
						"The minimum number of characters is 8",
						minLength(8)
					)
				},

				password_confirmation: {
					required: helpers.withMessage(
						"The field confirm password is required",
						required
					),
					minLength: helpers.withMessage(
						"The minimum number of characters is 8",
						minLength(8)
					),
					sameAs: helpers.withMessage(
						"Passwords do not match",
						sameAs(this.formData.password)
					)
				}
			}
		};
	}
};
</script>
